import { Component, OnDestroy, OnInit } from '@angular/core';
import { GerboxRegistration } from '../services/gerboxreg';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { GerboxLogon } from '../services/logon.service';
import { UsernameValidators } from '../services/username.validators';
import { UserRegistration } from '../services/registration.service';
import { User } from '../modules/regUserModel';
import { EventEmitterService } from '../services/event-emitter.service';
import { DataService } from '../services/data.service';
import { Gboxlangservice } from '../services/GerBoxLangService';
import { environment } from '../../environments/environment';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';

let o_germailUsr = new User({});

@Component({
  selector: 'app-gerbox',
  templateUrl: './gerbox.component.html',
  styleUrls: ['./gerbox.component.css'],
  standalone: true,
  imports: [MatButtonModule, MatInputModule, FormsModule, CommonModule, TranslateModule, MatCardModule, MatFormFieldModule, MatIconModule, MatSelectModule, ReactiveFormsModule, MatCheckboxModule, NgxSpinnerModule, MatTooltipModule]
})

export class GerboxComponent implements OnInit, OnDestroy {
  //############## Form Validation ######################################################################################
  showSpinner = true;
  spinnerText = false;
  germailuser: string = "";
  gpassword: string = "";
  gpassword2: string = "";
  bmobile: string = "";
  bmail: string = "";
  email: string = "";
  amount_fee: any;
  agreeagb: boolean;
  o_User: any;
  isButtonVisible: boolean = true;
  iUser: string = "";
  objusername: string;
  existUser: Boolean = false;
  private URL_SOGO = environment.URL_SOGO;
  private ADDMAIL = environment.ADDMAIL;

  frm = new UntypedFormGroup({
    email: new UntypedFormControl('', [
      //Validators.required,
      Validators.email,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]
    ),
    password: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(8)
    ]),
    password2: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(8)
    ]),
    user: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(4),
      UsernameValidators.excludeAddSign,
      UsernameValidators.cannotContainSpace
    ]),
    mobile: new UntypedFormControl('', [
      //Validators.required,
      Validators.minLength(10)
    ]),
  });

  get userinpreq() {
    return this.frm.get('user');
  }
  get emailinpreq() {
    return this.frm.get('email');
  }
  hide = true;
  get passwordinpreq() {
    return this.frm.get('password');
  }
  hide2 = true
  get password2inpreq() {
    return this.frm.get('password2');
  }
  get mobileNum() {
    return this.frm.get('mobile');
  }

  keyPressNumbers(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  //################## End Form Validation ################################################################################

  constructor(
    private service: GerboxRegistration,
    private spinner: NgxSpinnerService,
    private enter: GerboxLogon,
    private payment: UserRegistration,
    private eventEmitterService: EventEmitterService,
    private dataService: DataService,
    public translate: Gboxlangservice) {
    this.dataService.btnAgree.subscribe(agreeagb => this.agreeagb = agreeagb);
    this.dataService.btnAgree2.subscribe(agreeagb => this.agreeagb = agreeagb);
    this.dataService.btnHideSignUp.subscribe(isButtonVisible => this.isButtonVisible = isButtonVisible);
    this.dataService.paymentFee.subscribe(amount_fee => this.amount_fee = amount_fee);
  }

  update(): void {
    this.initGermailUser();
  }

  ngOnInit() {
    //triggered by paypal component
    if (this.eventEmitterService.submitUsr == undefined) {
      this.eventEmitterService.submitUsr = this.eventEmitterService.
        invokeSubmitUser.subscribe(() => {
          this.createMailBox();
        });
    }
  }

  getGermailUser(userSummary) {
    let emailusr = userSummary + this.ADDMAIL;
    this.dataService.setGermailUser(emailusr.toLocaleLowerCase());
    this.dataService.setUser(this.germailuser);
    o_germailUsr.setUserName(this.germailuser.toLocaleLowerCase());
    this.checkGermailBoxUser();
  }

  clickBackBtn() {
    this.dataService.setRequiredData(false);
  }

  setReqData() {
    if (!this.frm.valid) {
      this.translate.langi18n("ContactError", "msgerror");
      this.dataService.setRequiredData(false);
      return;
    }

    if (this.frm.valid) {
      if (o_germailUsr.getUserName() != "" && o_germailUsr.getUserName() === this.iUser) {
        this.dataService.setRequiredData(false);
        this.translate.langi18n("GerboxUserRes", "msginfoUser", "", "", this.germailuser);
      }
      else if (o_germailUsr.getPassword() != o_germailUsr.getPassword2()) {
        this.dataService.setRequiredData(false);
        this.translate.langi18n("GerboxPwVal", "msgerror");
        return
      }
      else if (o_germailUsr.getUserName() != this.iUser) {
        this.dataService.setRequiredData(true);
      }
    }
  }

  spin() {
    this.spinner.show();
    this.spinnerText = true;
  }

  initGermailUser() {
    //########### initialize GermailUser object ###########################
    o_germailUsr.setUserName(this.germailuser.toLocaleLowerCase());
    o_germailUsr.setUserPassword(this.gpassword);
    o_germailUsr.setUserPassword2(this.gpassword2);
    o_germailUsr.setAGB(this.agreeagb);
    o_germailUsr.setBackUpMail(this.bmail);
    o_germailUsr.setBackUpMobile(this.bmobile);
    this.setReqData();
  }

  checkGermailBoxUser() {
    this.service.getMailbox()
      .subscribe(response => {
        let swapres: any;
        let mailuser = [];
        swapres = response;
        for (let i = 0; i < swapres.length; i++) {
          mailuser.push(swapres[i].name);
          if (o_germailUsr.getUserName() === swapres[i].name) {
            let duplUser = swapres[i].name;
            this.translate.langi18n("GerboxUserRes", "msginfoUser", "", "", duplUser);
            this.iUser = swapres[i].name;
          }
        }
      }, error => {
        this.translate.langi18n("GerboxGetUserErr", "msgerror");
      });
  }

  createMailBox() {
    o_germailUsr.setFee(this.amount_fee);
    this.spin();
    this.service.postMailBox(o_germailUsr.getUserName(), o_germailUsr.getPassword())
      .subscribe(response => {
        this.sendInfoRegMail();
      }, error => {
        this.translate.langi18n("GerboxCreateMailBoxErr", "msgerror");
        this.spinner.hide();
      });
  }

  sendInfoRegMail() {
    this.service.postInfoMail(o_germailUsr.getUserName(), o_germailUsr.getPassword())
      .subscribe(response => {
        this.triggerInfoRegMail();
      }, error => {
        this.translate.langi18n("GerboxSendInfoMailErr", "msgerror");
        this.spinner.hide();
      });
  }

  triggerInfoRegMail() {
    this.service.trgRegMail()
      .subscribe(response => {
        this.payGermailBox();
      }, error => {
        this.translate.langi18n("GerboxTriggerInfoMailErr", "msgerror");
        this.spinner.hide();
      });
  }

  enterGermailBox() {
    let entername: any = o_germailUsr.getUserName() + this.ADDMAIL;
    this.enter.postUserCreds(entername, o_germailUsr.getPassword())
      .subscribe(response => {
        this.spinner.hide();
        this.spinnerText = false;
        this.translate.langi18n("GerboxCreatedMailBoxSuccess", "msgmbsuccess", "", "", o_germailUsr.getUserName());
        window.location.href = this.URL_SOGO;
      }, error => {
        this.translate.langi18n("GerboxGenAccountErr", "msgerror");
        this.spinner.hide();
      });
  }

  payGermailBox() {
    let mail: any = o_germailUsr.getUserName() + this.ADDMAIL;
    this.payment.postUsr(o_germailUsr.getUserName(), mail, o_germailUsr.getPassword(), o_germailUsr.getBackUpMail(), o_germailUsr.getBackUpMobile(), o_germailUsr.getAGB(), o_germailUsr.getFee())
      .subscribe(response => {
        this.spinner.hide();
        this.spinnerText = false;
        this.enterGermailBox();
      }, error => {
        this.translate.langi18n("GerboxGenPaymentAccountErr", "msgerror");
        this.spinner.hide();
      });
  }

  ngOnDestroy() {
    o_germailUsr.resetGermailUserObj();
    this.germailuser = "";
    this.gpassword = "";
    this.gpassword2 = "";
    this.bmobile = "";
    this.bmail = "";
    this.email = "";
    this.amount_fee = 0;
    this.agreeagb = false;
    this.iUser = "";
    this.dataService.buttonAgree(false);
    this.dataService.buttonAgree2(false);
  }
  onSubmit() {
  }
}
