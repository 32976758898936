import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserRegistration {
 
  private URL_REG = environment.URL_REG;
 
  constructor(private http: HttpClient) { }

  getUsr() {
    return this.http.get(this.URL_REG + '/getUser');
  }

  postUsr(inpUser, inpMail, inpPW, inpbmail, inpbmobile, agb, payment) {
  let germailboxFee = parseInt(payment);
  let regData = {
      name: inpUser,
      email: inpMail,
      password: inpPW,
      isAdmin: false,
      fee: germailboxFee,
      backupemail: inpbmail,
      backupmobile: inpbmobile,
      agreeagb:agb
    }
    return this.http.post(this.URL_REG + '/postUser', regData);
  }
}
