import { Component, Inject } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-agbdialog',
  templateUrl: 'agbdialog.component.html',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, TranslateModule]
})
export class AgbDialog {
  constructor(public dataservice: DataService,
    public dialogRef: MatDialogRef<AgbDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { agree: boolean }
  ) { }

  onIAgree() {
    this.dataservice.buttonAgree2(true);
  }
  onDisAgree() {
    this.dataservice.buttonAgree2(false);
  }
}
