<h2 mat-dialog-title>{{'AGBTitel'|translate}}</h2>
<mat-dialog-content>
    <h3><strong>{{'TermsPub'|translate}}</strong></h3>
    <p>Allgemeine Verkaufsbedingungen *) *) Bitte beachten Sie die
        Anmerkungen im Anhang 1! Bei der Nutzung der AGB im&nbsp;Fernabsatz
        sind zusätzliche Regelungen (z. B. Widerrufs- und Rückgaberecht) zu
        ergänzen. § 1 Angebot und Vertragsabschluss Die vom Besteller
        unterzeichnete Bestellung ist ein bindendes Angebot. Wir können dieses
        Angebot innerhalb von zwei Wochen durch Zusendung einer
        Auftragsbestätigung annehmen oder innerhalb dieser Frist die bestellte
        Ware zusenden. § 2 Überlassene Unterlagen An allen im Zusammenhang mit
        der Auftragserteilung dem Besteller überlassenen Unterlagen – auch in
        elektronischer Form –, wie z.B. Kalkulationen, Zeichnungen etc.,
        behalten wir uns das Eigentums- und Urheberrecht vor. Diese Unterlagen
        dürfen Dritten nicht zugänglich gemacht werden, es sei denn, wir
        erteilen dem Besteller unsere ausdrückliche schriftliche Zustimmung.
        Soweit wir das Angebot des Bestellers nicht innerhalb der Frist von §
        1 annehmen, sind diese Unterlagen uns unverzüglich zurückzusenden. § 3
        Preise und Zahlung In unseren Preisen ist (sind) die Umsatzsteuer (und
        Verpackungskosten) enthalten. Liefer- und Versandkosten sind in
        unseren Preisen (nicht) enthalten. Die Zahlung des Kaufpreises hat
        ausschließlich auf das umseitig genannte Konto zu erfolgen. Der Abzug
        von Skonto ist nur bei schriftlicher besonderer Vereinbarung zulässig.
        Sofern nichts anderes vereinbart wird, ist der Kaufpreis innerhalb von
        10 Tagen nach Lieferung zu zahlen (Alternativen: „ ... ist der
        Kaufpreis innerhalb von 21 Tagen nach Rechnungsstellung zahlbar“ oder
        „ ... ist der Kaufpreis bis zum - konkretes Datum - zahlbar“).
        Verzugszinsen werden in Höhe von 5 % über dem jeweiligen Basiszinssatz
        p. a. (siehe Anlage 1) berechnet. Die Geltendmachung eines höheren
        Verzugsschadens bleibt vorbehalten. Für den Fall, dass wir einen
        höheren Verzugsschaden geltend machen, hat der Besteller die
        Möglichkeit, uns nachzuweisen, dass der geltend gemachte
        Verzugsschaden überhaupt nicht oder in zumindest wesentlich
        niedrigerer Höhe angefallen ist. § 4 Aufrechnung und
        Zurückbehaltungsrechte Dem Besteller steht das Recht zur Aufrechnung
        nur zu, wenn seine Forderungen rechtskräftig festgestellt oder
        unbestritten sind. Zur Aufrechnung gegen unsere Ansprüche ist der
        Besteller auch berechtigt, wenn er Mängelrügen oder Gegenansprüche aus
        demselben Kaufvertrag geltend macht. Zur Ausübung eines
        Zurückbehaltungsrechts ist der Besteller nur insoweit befugt, als sein
        Gegenanspruch auf dem gleichen Vertragsverhältnis beruht. § 5
        Lieferzeit Soweit kein ausdrücklich verbindlicher Liefertermin
        vereinbart wurde, sind unsere Liefertermin bzw. Lieferfristen
        ausschließlich unverbindliche Angaben. Der Beginn der von uns
        angegebenen Lieferzeit setzt die rechtzeitige und ordnungsgemäße
        Erfüllung der Verpflichtungen des Bestellers voraus. Die Einrede des
        nicht erfüllten Vertrages bleibt vorbehalten. Der Besteller kann X
        Wochen nach Überschreitung eines unverbindlichen
        Liefertermins/Lieferfrist uns in Textform auffordern binnen einer
        angemessenen Frist zu liefern. Sollten wir einen ausdrücklichen
        Liefertermin/eine Lieferfrist schuldhaft nicht einhalten oder wenn wir
        aus anderem Grund in Verzug geraten, so muss der Besteller uns eine
        angemessene Nachfrist zur Bewirkung der Leistung setzen. Wenn wir die
        Nachfrist fruchtlos verstreichen lassen, so ist der Besteller
        berechtigt, vom Kaufvertrag zurückzutreten. Kommt der Besteller in
        Annahmeverzug oder verletzt er schuldhaft sonstige
        Mitwirkungspflichten, so sind wir berechtigt, den uns hierdurch
        entstehenden Schaden, einschließlich etwaiger Mehraufwendungen ersetzt
        zu verlangen. Weitergehende Ansprüche bleiben vorbehalten. Dem
        Besteller bleibt seinerseits vorbehalten nachzuweisen, dass ein
        Schaden in der verlangten Höhe überhaupt nicht oder zumindest
        wesentlich niedriger entstanden ist. Die Gefahr eines zufälligen
        Untergangs oder einer zufälligen Verschlechterung der Kaufsache geht
        in dem Zeitpunkt auf den Besteller über, in dem dieser in Annahme-
        oder Schuldnerverzug gerät. Weitere gesetzliche Ansprüche und Rechte
        des Bestellers wegen eines Lieferverzuges bleiben unberührt. § 6
        Eigentumsvorbehalt Wir behalten uns das Eigentum an der gelieferten
        Sache bis zur vollständigen Zahlung sämtlicher Forderungen aus dem
        Liefervertrag vor. Der Besteller ist verpflichtet, solange das
        Eigentum noch nicht auf ihn übergegangen ist, die Kaufsache pfleglich
        zu behandeln. Insbesondere ist er verpflichtet, diese auf eigene
        Kosten gegen Diebstahl-, Feuer- und Wasserschäden ausreichend zum
        Neuwert zu versichern (Hinweis: nur zulässig bei Verkauf hochwertiger
        Güter). Müssen Wartungs- und Inspektionsarbeiten durchgeführt werden,
        hat der Besteller diese auf eigene Kosten rechtzeitig auszuführen.
        Solange das Eigentum noch nicht übergegangen ist, hat uns der
        Besteller unverzüglich in Textform zu benachrichtigen, wenn der
        gelieferte Gegenstand gepfändet oder sonstigen Eingriffen Dritter
        ausgesetzt ist. Soweit der Dritte nicht in der Lage ist, uns die
        gerichtlichen und außergerichtlichen Kosten einer Klage gemäß § 771
        ZPO zu erstatten, haftet der Besteller für den uns entstandenen
        Ausfall. Die Be- und Verarbeitung oder Umbildung der Kaufsache durch
        den Besteller erfolgt stets Namens und im Auftrag für uns. In diesem
        Fall setzt sich das Anwartschaftsrecht des Bestellers an der Kaufsache
        an der umgebildeten Sache fort. Sofern die Kaufsache mit anderen, uns
        nicht gehörenden Gegenständen verarbeitet wird, erwerben wir das
        Miteigentum an der neuen Sache im Verhältnis des objektiven Wertes
        unserer Kaufsache zu den anderen bearbeiteten Gegenständen zur Zeit
        der Verarbeitung. Dasselbe gilt für den Fall der Vermischung. Sofern
        die Vermischung in der Weise erfolgt, dass die Sache des Bestellers
        als Hauptsache anzusehen ist, gilt als vereinbart, dass der Besteller
        uns anteilmäßig Miteigentum überträgt und das so entstandene
        Alleineigentum oder Miteigentum für uns verwahrt. Zur Sicherung
        unserer Forderungen gegen den Besteller tritt der Besteller auch
        solche Forderungen an uns ab, die ihm durch die Verbindung der
        Vorbehaltsware mit einem Grundstück gegen einen Dritten erwachsen; wir
        nehmen diese Abtretung schon jetzt an. Wir verpflichten uns, die uns
        zustehenden Sicherheiten auf Verlangen des Bestellers freizugeben,
        soweit ihr Wert die zu sichernden Forderungen um mehr als 20 %
        übersteigt. § 7 Gewährleistung und Mängelrüge Soweit die in unseren
        Prospekten, Anzeigen und sonstigen Angebotsunterlagen enthaltenen
        Angaben nicht von uns ausdrücklich als verbindlich bezeichnet worden
        sind, sind die dort enthaltenen Abbildungen oder Zeichnungen nur
        annähernd maßgebend, Soweit der gelieferte Gegenstand nicht die
        zwischen dem Besteller und uns vereinbarte Beschaffenheit hat oder er
        sich nicht für die nach unserem Vertrag vorausgesetzten oder die
        Verwendung allgemein eignet oder er nicht die Eigenschaften, die der
        Besteller nach unseren öffentlichen Äußerungen erwarten konnten, hat,
        so sind wir zur Nacherfüllung verpflichtet. dies gilt nicht, wenn wir
        aufgrund der gesetzlichen Regelungen zur Verweigerung der
        Nacherfüllung berechtigt sind. Der Besteller hat zunächst die Wahl, ob
        die Nacherfüllung durch Nachbesserung oder Ersatzlieferung erfolgen
        soll. Wir sind jedoch berechtigt, die vom Besteller gewählte Art der
        Nacherfüllung zu verweigern, wenn sie nur mit unverhältnismäßigen
        Kosten möglich ist und die andere Art der Nacherfüllung ohne
        erhebliche Nachteile für den Besteller bleibt. Während der
        Nacherfüllung sind die Herabsetzung des Kaufpreises oder der Rücktritt
        vom Vertrag durch den Besteller ausgeschlossen. Eine Nachbesserung
        gilt mit dem erfolglosen zweiten Versuch als fehlgeschlagen, wenn sich
        nicht insbesondere aus der Art der Sache oder des Mangels oder den
        sonstigen Umständen etwas anderes ergibt. Ist die Nacherfüllung
        fehlgeschlagen oder haben wir die Nacherfüllung insgesamt verweigert,
        kann der Besteller nach seiner Wahl Herabsetzung des Kaufpreises
        (Minderung) verlangen oder den Rücktritt vom Vertrag erklären.
        Schadensersatzansprüche zu den nachfolgenden Bedingungen wegen des
        Mangels kann der Besteller erst geltend machen, wenn die Nacherfüllung
        fehlgeschlagen ist oder wir die Nacherfüllung verweigert haben. Das
        Recht des Bestellers zur Geltendmachung von weitergehenden
        Schadensersatzansprüchen zu den nachfolgenden Bedingungen bleibt davon
        unberührt. Wir haften unbeschadet vorstehender Regelungen und der
        nachfolgenden Haftungsbeschränkungen uneingeschränkt für Schäden an
        Leben, Körper und Gesundheit, die auf einer fahrlässigen oder
        vorsätzlichen Pflichtverletzung unserer gesetzlichen Vertretern oder
        unserer Erfüllungsgehilfen beruhen, sowie für Schäden, die von der
        Haftung nach dem Produkthaftungsgesetz umfasst werden, sowie für alle
        Schäden, die auf vorsätzlichen oder grob fahrlässigen
        Vertragsverletzungen sowie Arglist, unserer gesetzlichen Vertreter
        oder unserer Erfüllungsgehilfen beruhen. Soweit wir bezüglich der Ware
        oder Teile derselben eine Beschaffenheits- und/oder
        Haltbarkeitsgarantie abgegeben hat, haften wir auch im Rahmen dieser
        Garantie. Für Schäden, die auf dem Fehlen der garantierten
        Beschaffenheit oder Haltbarkeit beruhen, aber nicht unmittelbar an der
        Ware eintreten, haften wir allerdings nur dann, wenn das Risiko eines
        solchen Schadens ersichtlich von der Beschaffenheits- und
        Haltbarkeitsgarantie erfasst ist. Wir haften auch für Schäden, die
        durch einfache Fahrlässigkeit verursacht werden, soweit diese
        Fahrlässigkeit die Verletzung solcher Vertragspflichten betrifft,
        deren Einhaltung für die Erreichung des Vertragszwecks von besonderer
        Bedeutung ist (Kardinalpflichten). Wir haften jedoch nur, soweit die
        Schäden in typischer Weise mit dem Vertrag verbunden und vorhersehbar
        sind. Bei einfachen fahrlässigen Verletzungen nicht
        vertragswesentlicher Nebenpflichten haften wir im Übrigen nicht. Die
        in den Sätzen 1 – 3 enthaltenen Haftungsbeschränkungen gelten auch,
        soweit die Haftung für die gesetzlichen Vertreter, leitenden
        Angestellten und sonstigen Erfüllungsgehilfen betroffen ist. Eine
        weitergehende Haftung ist ohne Rücksicht auf die Rechtsnatur des
        geltend gemachten Anspruchs ausgeschlossen. Soweit unsere Haftung
        ausgeschlossen oder beschränkt ist, gilt dies auch für die persönliche
        Haftung unserer Angestellten, Arbeitnehmer, Mitarbeiter, Vertreter und
        Erfüllungsgehilfen. Die Gewährleistungsfrist beträgt 2 Jahre,
        gerechnet ab Gefahrübergang (Hinweis: möglich ist eine Reduzierung auf
        ein Jahr in AGBs bei gebrauchten Sachen. Bei Baumaterialien – sofern
        eingebaut – beträgt die Gewährleistungsfrist 5 Jahre, falls die
        Baumaterialien gebraucht sind ist eine Reduzierung in AGBs auf 1 Jahr
        möglich). Diese Frist gilt auch für Ansprüche auf Ersatz von
        Mangelfolgeschäden, soweit keine Ansprüche aus unerlaubter Handlung
        geltend gemacht werden. § 8 Sonstiges Dieser Vertrag und die gesamten
        Rechtsbeziehungen der Parteien unterliegen dem Recht der
        Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts (CISG).
        Sollten einzelne Bestimmungen dieses Vertrages unwirksam sein oder
        werden oder eine Lücke enthalten, so bleiben die übrigen Bestimmungen
        hiervon unberührt. Anhang 1: AnmerkungenTransparenzgebot Dieses Gebot
        bedeutet, dass eine Klausel in AGB im Zweifel auch dann unangemessen
        benachteiligend ist, wenn sie nicht klar und verständlich ist. Dieses
        Gebot bedeutet, dass intransparente Klauseln per se, ohne Hinzutreten
        einer inhaltlichen unangemessenen Benachteiligung des
        Vertragspartners, als unwirksam zu betrachten sind. Ferner bedeutet
        dies auch, dass das Transparenzgebot auch für Preisbestimmungen und
        leistungsbeschreibende Klauseln, die grundsätzlich von der
        Inhaltskontrolle ausgenommen sind, gilt.</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button style="background: red;" mat-button mat-dialog-close
        (click)="onDisAgree()">{{'TermsbtnCn'|translate}}</button>
    <button style="background: green;" mat-button [mat-dialog-close]="true" cdkFocusInitial
        (click)="onIAgree()">{{'TermsbtnAg'|translate}}</button>
</mat-dialog-actions>