<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <!-- Adapting the size for different browsers and mobile devices-->
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <!--SEO optimization -->
    <meta name="keywords" content="HTML, CSS" />
    <meta name="description" content="Terms and condition" />
    <title>agb</title>
  </head>
      <!-- ####################### mat-card text###########################################################################-->
          <div class="container">
          <mat-card class="box">
            <mat-card-header>
              <mat-card-title>{{'AGBTitel'|translate}}</mat-card-title>
            </mat-card-header><br><br>
          <mat-card-content>
            <!-- <button mat-button (click)="openAGB()">Read terms</button> -->
            <section class="germail-section">
              <mat-checkbox class="germail-margin"
               (click)="clickAGB(checked)" [(ngModel)]="checked">
              </mat-checkbox>
              <a (click)="openAGB()">{{'AGBAgree'|translate}}</a>
            </section>
            <section class="germail-section">
              <mat-checkbox class="germail-margin">
              </mat-checkbox>
              <a>{{'AGBPrivacy'|translate}}</a>
            </section>
          </mat-card-content>
        </mat-card>
      </div>
</html>

