import { Component, OnInit, NgModule, ViewChild } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { NgxPayPalModule } from 'ngx-paypal';
import { DataService } from '../services/data.service';
import { EventEmitterService } from '../services/event-emitter.service';
import { Gboxlangservice } from '../services/GerBoxLangService';
import { GerboxComponent } from '../gerbox/gerbox.component';
import { TopupComponent } from '../topup/topup.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

interface Zahlen {
  value: any;
}
@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.css'],
  standalone: true,
  imports: [MatCardModule, MatFormFieldModule, MatIconModule, MatSelectModule, CommonModule, FormsModule, TranslateModule, NgxPayPalModule],
})
export class PaypalComponent implements OnInit {
  @ViewChild(GerboxComponent, { static: false }) childGbox: GerboxComponent;
  formEmail: any;
  num: any;
  mongoFee: any;
  hideFee;
  activateSubmit: Boolean;
  paymentTxt: Boolean;

  public mySelection: NgModule;
  numbers: Zahlen[] = [
    { value: '1' },
    { value: '3' },
    { value: '6' },
    { value: '12' },
    { value: '15' },
    { value: '18' },
    { value: '24' },
  ];

  public payPalConfig?: IPayPalConfig;
  constructor(private data: DataService,
    private eventEmitterService: EventEmitterService,
    public translate: Gboxlangservice) {
    this.data.email.subscribe(formEmail => this.formEmail = formEmail);
    this.data.b_btnActivateSubmit.subscribe(activateSubmit => this.activateSubmit = activateSubmit);
    this.data.b_btnPaymentText.subscribe(paymentTxt => this.paymentTxt = paymentTxt)
  }
  ngOnInit(): void {
    this.initConfig();
    this.num = "1";
    this.getFee();
  }
  getFee() {
    this.mongoFee = this.num;
    this.data.setPaymentFee(this.mongoFee);
  }

  // ################################### Paypal btn ########################################
  private initConfig(): void {
    this.payPalConfig = {
      currency: 'EUR',
      clientId: 'sb',
      createOrderOnClient: (data) => <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'EUR',
                value: this.num,
                breakdown: {
                  item_total: {
                    currency_code: 'EUR',
                    value: this.num
                  }
                }
              },
              items: [
                {
                  name: 'GermailBox',
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: 'EUR',
                    value: this.num,
                  },
                }
              ]
            }
          ]
        },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove', details);
          this.data.setconfirmPayment(true);
          this.translate.langi18n("PaypalApproval", "msgsuccess");
        });
      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization', data);
        this.data.setconfirmPayment(true);
        this.translate.langi18n("PaypalPaid", "msgsuccess");
        if (this.activateSubmit === true) {
          //this.childGbox.createMailBox();
          this.eventEmitterService.onUserSubmitClick();
        }
        if (this.activateSubmit === false) {
        //this.childTopUp.fetchMongoID();
          this.eventEmitterService.onChargeUserAccount();
        }
        this.data.setSubmitActivation(false);
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
        this.data.setconfirmPayment(false);
        this.translate.langi18n("PaypalCanceled", "msgerror");
      },
      onError: err => {
        console.log('OnError', err);
        this.data.setconfirmPayment(false);
        //this.translate.langi18n("PaypalSelect","msgerror");
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
      },
    };
  }
}