import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ContactMail } from '../services/contact.service';
import {Gboxlangservice} from '../services/GerBoxLangService';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.css'],
  standalone: true,
  imports: [MatButtonModule, MatInputModule, CommonModule, RouterModule, FormsModule, TranslateModule, MatCardModule, MatFormFieldModule, MatIconModule, MatSelectModule, ReactiveFormsModule, MatCheckboxModule]
})

export class ContactComponent implements OnInit {

  //############## Form Validation ######################################################################################

  myForm = new UntypedFormGroup({

    email: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
    ),

    subject: new UntypedFormControl('', [
      Validators.required,
      //Validators.minLength(5)

    ]),
    body: new UntypedFormControl('', [
      Validators.required,
      //Validators.minLength(5),
    ])
  });

  formProperty(f) {
    console.log(f);
  }

  get emailinpreq() {
    return this.myForm.get('email');
  }

  get subjectinpreq() {
    return this.myForm.get('subject');
  }

  get bodyinpreq() {
    return this.myForm.get('body');
  }

  //################## End Form Validation ################################################################################

  constructor( private formBuilder: UntypedFormBuilder, 
              private mailService:ContactMail,
              public translate:Gboxlangservice) { }

  ngOnInit() {

  }

  sendMail(mailadress,subj, body) {
    if (!this.myForm.valid) {
      this.translate.langi18n("ContactError","msgerror");
      return;
    }

    this.mailService.postContactMail(mailadress,subj,body)
      .subscribe(response => {
        this.translate.langi18n("ContactMailPost","msgsuccess")
        this.triggerContactMail();
      }, error => {
        this.translate.langi18n("ContactMailPostErr","msgerror")
      });
  }
  triggerContactMail() {
    this.mailService.trgRegMail()
      .subscribe(response => {
        let message: any = response;
      }, error => {
        this.translate.langi18n("ContactMailPostErr","msgerror")
      });
  }
  onSubmit() {
    if (this.myForm.valid) {
      console.log("Form Submitted!", this.myForm.value);
      this.myForm.reset();
    }
  }
}
