let germailUser = {
  name: "",
  email: "",
  fee: Number,
  agreeagb: Boolean,
  password: "",
  password2: "",
  backupemail: "",
  backupmobile: Number
}

export class User{

  constructor(private germailUser) {}

  getUserName(){
    return this.germailUser.name;
  }

  setUserName(name) {
    this.germailUser.name = name;
    return this.germailUser.name;
  }

  getEmail(){
    return this.germailUser.email;
  }

  getPassword(){
    return this.germailUser.password;
  }
  setUserPassword(pw) {
    this.germailUser.password = pw;
    return this.germailUser.password;
  }

  getPassword2(){
    return this.germailUser.password2;
  }
  setUserPassword2(pw2) {
    this.germailUser.password2 = pw2;
    return this.germailUser.password2;
  }

  getFee(){
    return this.germailUser.fee;
  }
  setFee(fee) {
    this.germailUser.fee = fee;
    return this.germailUser.fee;
  }

  getAGB(){
    return this.germailUser.agreeagb;
  }
  setAGB(agb) {
    this.germailUser.agreeagb = agb;
    return this.germailUser.agreeagb;
  }

  getBackUpMail() {
    return this.germailUser.backupemail;
  }
  setBackUpMail(bmail) {
    this.germailUser.backupemail = bmail;
    return this.germailUser.backupemail;
  }

  getBackUpMobile() {
    return this.germailUser.backupmobile;
  }
  setBackUpMobile(bmobile) {
    this.germailUser.backupmobile = bmobile;
    return this.germailUser.backupmobile;
  }

  resetGermailUserObj() {
    let clear = this.germailUser = {};
    return clear;
  }
}

