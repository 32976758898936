import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { routesConf } from './app.routes';
import { provideToastr } from 'ngx-toastr';
import { DataService } from './services/data.service';
import { Gboxlangservice } from './services/GerBoxLangService'

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export const appConfig: ApplicationConfig = {
    providers: [provideRouter(routesConf), provideHttpClient(), provideToastr(), provideAnimations(), importProvidersFrom(HttpClientModule),
    importProvidersFrom(
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })), DataService, Gboxlangservice]
};
