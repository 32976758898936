<!DOCTYPE html>
<html lang="en">

  <head>
    <meta charset="UTF-8" />
    <!-- Adapting the size for different browsers and mobile devices-->
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <!--SEO optimization -->
    <meta name="keywords" content="HTML, CSS" />
    <meta name="description" content="payment" />
    <title>payment</title>
  </head>

  <body class="d-flex flex-column">
    <ngx-spinner class="spinner" color="goldenrod" size="medium" *ngIf="showSpinner" type="square-jelly-box"
      bdColor="rgba(51,51,51,0.5)">
      <p *ngIf="spinnerText">Loading...</p>
    </ngx-spinner>
    <div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
      <mat-card class="box">
        <mat-card-header>
          <mat-card-title class="mTitel">{{'Top up Login'|translate}}</mat-card-title>
        </mat-card-header><br>
        <form class="form" [formGroup]="frm" (change)="formProperty(frm)" (ngSubmit)="onSubmit()">
          <mat-card-content>
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>{{ 'GermailBoxLoginUser' | translate }}</mat-label>
              <input matInput placeholder="{{'TopUpUser'|translate}}" id="email" formControlName="email" required #user />
              <mat-error
                *ngIf="emailinpreq?.errors?.['required']                                                              && (emailinpreq.dirty || emailinpreq.touched)">
                {{ 'GermailBoxUserVal' | translate }}
              </mat-error>
              <!-- Pattern Error -->
              <mat-error
                *ngIf="emailinpreq?.errors?.['pattern'] 
                                                                                                                        && (emailinpreq.dirty || emailinpreq.touched)">
                {{ 'GermailBoxUserValPattern' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>{{ 'GermailBoxLoginPw' | translate }}</mat-label>
              <input id="password" matInput placeholder="{{'TopUpPw'|translate}}" [type]="hide ? 'password' : 'text'"
                formControlName="password" required #password />
              <mat-icon matSuffix (click)="hide = !hide">{{
                hide ? "visibility_off" : "visibility"
                }}</mat-icon>
              <mat-hint *ngIf="!passwordinpreq.value"></mat-hint>
              <mat-error *ngIf="passwordinpreq?.touched && passwordinpreq?.invalid">
                {{ 'GermailBoxUserValPw' | translate }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
          <button mat-stroked-button style="color:white; padding:30px;width: 100%;" (click)="TopUpLogin(user, password)">
            {{'TopUpLogin'|translate}}
          </button>
          <div></div>
        </form>
      </mat-card>
    </div>
  </body>

</html>