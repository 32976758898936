<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <!-- Adapting the size for different browsers and mobile devices-->
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <!--SEO optimization -->
    <meta name="keywords" content="HTML, CSS" />
    <meta name="description" content="welcome" />
    <title>welcome</title>
  </head>
    <div class="container" >
      <mat-card class="box">
        <mat-card-header>
          <mat-card-title class="card-titel">{{'LandingPage'|translate}}</mat-card-title>
        </mat-card-header>
        <br><br>
        <form class="form">
          <mat-card-content>
              <p class="font-color">{{'IntroText'|translate}}</p>
              <p class="font-color2">{{'Sponsor'|translate}} <a routerLink="../contact">{{'TextMe'|translate}}</a></p>
          </mat-card-content>
        </form>
      </mat-card>
  </div>
</html>