import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GerboxLogon {
    private URL_CONNECT = environment.URL_CONNECT;
    
    constructor(private http: HttpClient) { }

    postUserCreds(inpUser, inpPW) {
        let LogonData = {
            password: inpPW,
            rememberLogin: 0,
            userName: inpUser
        }
        return this.http.post(this.URL_CONNECT, LogonData, { observe: 'response', withCredentials: true, responseType: 'json' });//, responseType: "arraybuffer" });
    }
}
