import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DataService } from '../services/data.service';
import { MatStepper } from '@angular/material/stepper';
import { Gboxlangservice } from '../services/GerBoxLangService';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatStepperModule } from '@angular/material/stepper';
import { GerboxComponent } from '../gerbox/gerbox.component';
import { AgbComponent } from '../agb/agb.component';
import { PaypalComponent } from '../paypal/paypal.component';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }],
  standalone: true,
  imports: [MatMenuModule, MatListModule, MatInputModule, CommonModule, MatButtonModule, RouterModule, FormsModule, TranslateModule, MatCardModule, MatFormFieldModule, MatIconModule, MatSelectModule, ReactiveFormsModule, MatCheckboxModule, NgxSpinnerModule, MatTooltipModule, MatStepperModule, GerboxComponent, AgbComponent, PaypalComponent]
})

export class RegistrationComponent implements OnInit {

  @ViewChild('stepper', { static: false }) private stepper: MatStepper;
  @ViewChild(GerboxComponent, { static: false }) childC: GerboxComponent;

  btnSubmit: boolean = true;
  infoFormGroup: UntypedFormGroup;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  thirdFormGroup: UntypedFormGroup;
  showSpinner = true;
  spinnerText = true;
  agb: Boolean;
  userEmail: any;
  userFee: any;
  reqData: Boolean;
  paymentConfirm: Boolean;
  selectedIndex: any;
  step: Number = 2;
  userGermailbox: any;
  showChild: boolean = true;

  constructor(private _formBuilder: UntypedFormBuilder,
    private data: DataService,
    public translate: Gboxlangservice,) {
  }

  ngOnInit() {
    this.infoFormGroup = this._formBuilder.group({
      infoCtrl: ['', Validators.required]
    });
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
      //x:['',Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required,
        this.data.gerUsr.subscribe(userEmail => this.userEmail = userEmail),
        this.data.paymentFee.subscribe(userFee => this.userFee = userFee)
      ]
    });
    this.thirdFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required]
    });
    this.data.btnAgree.subscribe(agb => this.agb = agb);
    this.data.btnAgree2.subscribe(agb => this.agb = agb);
    this.data.i_btnConfirmPayment.subscribe(paymentConfirm => this.paymentConfirm = paymentConfirm);
    this.data.i_btnRequiredData.subscribe(reqData => this.reqData = reqData);
    this.data.user.subscribe(userGermailbox => this.userGermailbox = userGermailbox);
  }
  backValidateUser() {
    this.childC.clickBackBtn();
  }

  checkReqInput(s: number) {
    this.childC.update();
    this.data.setSubmitActivation(true);

    if (this.reqData == false) {
      this.step = s;
      setTimeout(() => this.stepper.selectedIndex = (s - 1));
      //setTimeout(() => this.stepper.selectedIndex);
    } else if (s == 3 && this.agb == false) {
      this.translate.langi18n("AgreeTerms", "msgerror");
      this.step = s;
      setTimeout(() => this.stepper.selectedIndex = (s - 1));
    } else if (s == 4 && this.userFee == "") {
      this.translate.langi18n("EnterMoney", "msgerror");
      this.step = s;
      setTimeout(() => this.stepper.selectedIndex = (s - 1));
    } else if (s == 4 && this.paymentConfirm == false) {
      this.step = s;
      setTimeout(() => this.stepper.selectedIndex = (s - 1));
    }
  }
  onStepChange(event: any): void {
    const selectedIndex = event.selectedIndex; // Index of the newly selected step
    const previousIndex = event.previouslySelectedIndex; // Index of the previous step

    console.log('Step changed to:', selectedIndex);
    console.log('Previous step was:', previousIndex);

    // Process logic based on the selected step
    switch (selectedIndex) {
      case 0: // Step 1
        console.log('Processing Step 1');
        break;
      case 1: // Step 2
        console.log('Processing Step 2');
        this.checkReqInput(2); // Custom validation for Step 2
        break;
      case 2: // Step 3
        console.log('Processing Step 3');
        this.checkReqInput(3); // Custom validation for Step 3
        break;
      case 3: // Step 4
        console.log('Processing Step 4');
        if (!this.paymentConfirm) {
          console.log('Payment confirmation required for Step 4.');
          this.stepper.selectedIndex = previousIndex; // Prevent moving to Step 4
        }
        break;
      default:
        console.log('Unknown step.');
    }
  }
}

