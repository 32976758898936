import { Component, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.css'],
  standalone: true,
  imports: [RouterModule, FormsModule, TranslateModule, MatCardModule, MatFormFieldModule, MatIconModule, MatSelectModule, ReactiveFormsModule, MatCheckboxModule, MatTooltipModule]
})
export class WelcomeComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {
  }

}
