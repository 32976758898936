export const environment = {
  production: false,
  INQUIRYMAIL: 'frage@germailbox.de',
  SUBDOMAIN: 'mail.germailbox.de',
  INFOMAIL: 'info@germailbox.de',
  DOMAIN: 'germailbox.de',
  URL_LOGIN: 'https://germailbox.de/SOGo/',
  ADDMAIL: '@germailbox.de',
  API_URL: 'https://germailbox.de/api',
  URL:'https://germailbox.de',
  URL_SOGO:'https://germailbox.de/SOGo/',
  URL_GET_MB :'https://germailbox.de/crmboxatgermfo/api/v1/get/mailbox',
  URL_CREATE_MB :'https://germailbox.de/crmboxatgermfo/api/v1/add/mailbox',
  URL_EDIT:'https://germailbox.de/crmboxatgermfo/api/v1/edit/mailbox',
  URL_IMAIL:'https://germailbox.de/api/mb',
  URL_CONNECT:'https://germailbox.de/SOGo/connect',
  URL_REG:'https://germailbox.de/api/users',
  API_KEY:'B4182E-0CC4E5-1BD604-F76946-104683',
  INFO_PW:'Password1!',
};