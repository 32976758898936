<!DOCTYPE html>
<html lang="en">

  <head>
    <meta charset="UTF-8" />
    <!-- Adapting the size for different browsers and mobile devices-->
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <!--SEO optimization -->
    <meta name="keywords" content="HTML, CSS" />
    <meta name="description" content="paypal" />
    <title>paypal</title>
  </head>
  <div class="container">
    <mat-card class="box">
      <mat-card-content>
        <mat-card-header>
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title>{{'PaypalTitel'|translate}}</mat-card-title><br><br><br>
          <mat-card-subtitle>{{'PaypalMoneyChoice'|translate}}</mat-card-subtitle>
        </mat-card-header><br>
        <mat-form-field class="form-field" appearance="outline">
          <mat-select [disabled]="hideFee" required #selectFee [(ngModel)]="num">
            <mat-option *ngFor="let number of numbers" [(value)]="number.value" (click)="getFee()">{{ number.value + "
              €" }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <p class="note" *ngIf="this.paymentTxt">{{'PaypalHint'|translate}}
        </p>
      </mat-card-content>
    </mat-card>

    <div class="box">
      <mat-card>
        <mat-card-content>
          <mat-card-header>
            <mat-card-title></mat-card-title>
            <mat-card-subtitle>{{'PaypalSelectPayment'|translate}}</mat-card-subtitle>
          </mat-card-header><br>
          <ngx-paypal [config]="payPalConfig"></ngx-paypal>
        </mat-card-content>
      </mat-card>
      </div>
  </div>
</html>