<mat-toolbar class="custom-toolbar">
  <button mat-icon-button (click)="sidenav.toggle()">
    <mat-icon>menu</mat-icon>
  </button>
  <span class="spacer"></span>
  <a class="startBtn" routerLink="/welcome">{{'Home'|translate}}</a>
  <span class="spacer"></span>
  <a class="startBtn" *ngIf="this.isButtonVisible" routerLink="payment" routerLinkActive="active">{{'Top up your
    account' | translate }}</a>
  <span class="spacer"></span>
  <a class="startBtn"
            *ngIf="this.isButtonVisible"
            routerLink="login"
            routerLinkActive="active"
            >{{'Login' | translate }}</a>
            <span class="spacer"></span>
            <a class="startBtn" *ngIf="this.isButtonVisible" routerLink="signup" routerLinkActive="active"
              (click)="hideSignUpBtn()">{{'Sign up' | translate }}</a>
            <span class="spacer"></span>
            <button mat-button *ngIf="this.isButtonVisible2" (click)="returnWelcome()" routerLinkActive="active">logout</button>
            <span class="spacer"></span>
            <ng-container *ngIf="languages">
              <button mat-button [matMenuTriggerFor]="languageMenu" class="languageDropDown">
                <img *ngIf="selectedLanguage" [src]="currentFlag" class="flag" />
                {{ selectedLanguage }}
              </button>
              <mat-menu #languageMenu="matMenu">
                <button mat-menu-item *ngFor="let language of languages" (click)="switchLanguage(language.code)"
                  [class.selected]="language.code === selectedLanguage">
                  <img [src]="language.flag" class="flag" />{{ language.code }}
                </button>
              </mat-menu>
            </ng-container>
  </mat-toolbar>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav mode="over" class="sidenav">
    <nav>
      <a class="startBtn" routerLink="/welcome" (click)="closeSidenav()">
        <mat-icon>home</mat-icon> {{ 'Home' | translate }}
      </a>
      <a class="startBtn" routerLink="/login" (click)="closeSidenav()">
        <mat-icon>person_add</mat-icon> {{'Login' | translate }}
      </a>
    </nav>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
    <footer class="footer">
      <div class="container footer-content">
        <button mat-button routerLink="/impressum" color="primary">
          <mat-icon>info</mat-icon> {{'Impressum' | translate }}
        </button>
        <button mat-button routerLink="/contact" color="primary" (mouseenter)="showContactEmail = true"
          (mouseleave)="showContactEmail = false">
          <mat-icon>mail</mat-icon> {{ 'Contact' | translate }}
        </button>
        <button mat-button color="primary" routerLink="/agb">
          <mat-icon>assignment</mat-icon> {{ 'Terms' | translate }}
        </button>
        <p style="color:white">&copy; FrenzelTech {{ currentYear }}</p>
      </div>
    </footer>
    </mat-sidenav-content>
    </mat-sidenav-container>