import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()

export class DataService {

    private btnShow = new BehaviorSubject<boolean>(true);
    currentValue = this.btnShow.asObservable();

    private btnShow2 = new BehaviorSubject<boolean>(false);
    currentValue2 = this.btnShow2.asObservable();

    private btnSignUp = new BehaviorSubject<boolean>(true);
    btnHideSignUp = this.btnSignUp.asObservable();

    private token = new BehaviorSubject<string>('');
    TokenValue = this.token.asObservable();

    private fetchKurs = new BehaviorSubject<string>('');
    courses = this.fetchKurs.asObservable();

    private fetchUser = new BehaviorSubject<string>('');
    user = this.fetchUser.asObservable();

    private fetchEmail = new BehaviorSubject<string>('');
    email = this.fetchEmail.asObservable();

    private fetchID = new BehaviorSubject<string>('');
    id = this.fetchID.asObservable();

    private fetchName = new BehaviorSubject<string>('');
    name = this.fetchName.asObservable();

    private i_btnAgb = new BehaviorSubject<boolean>(false);
    btnAgree = this.i_btnAgb.asObservable();

    private i_btnAgb2 = new BehaviorSubject<boolean>(false);
    btnAgree2 = this.i_btnAgb2.asObservable();

    private i_btnReqData = new BehaviorSubject<boolean>(false);
    i_btnRequiredData = this.i_btnReqData.asObservable();

    private i_btnconfPay = new BehaviorSubject<boolean>(false);
    i_btnConfirmPayment = this.i_btnconfPay.asObservable();

    private b_btnhdPay = new BehaviorSubject<boolean>(false);
    b_btnHidePayment = this.b_btnhdPay.asObservable();

    private i_fee = new BehaviorSubject<any>('');
    paymentFee = this.i_fee.asObservable();

    private germailUser = new BehaviorSubject<any>('');
    gerUsr = this.germailUser.asObservable();

    private b_btnActSub = new BehaviorSubject<boolean>(false);
    b_btnActivateSubmit = this.b_btnActSub.asObservable();

    private b_btnPayTxt = new BehaviorSubject<boolean>(true);
    b_btnPaymentText = this.b_btnPayTxt.asObservable();

    private ftcLang = new BehaviorSubject<string>('');
    fetchLanguage = this.ftcLang.asObservable();

    constructor() { }

    setLanguage(fetchLanguage: string) {
      this.ftcLang.next(fetchLanguage)
    }

    setPaymentText(b_btnPaymentText: boolean) {
      this.b_btnPayTxt.next(b_btnPaymentText)
    }

    setSubmitActivation(b_btnActivateSubmit: boolean) {
      this.b_btnActSub.next(b_btnActivateSubmit)
    }

    changeValue(currentValue: boolean) {
        this.btnShow.next(currentValue)
    }

    changeValue2(currentValue2: boolean) {
        this.btnShow2.next(currentValue2)
    }

    onHideSignIn(btnHideSignUp: boolean) {
      this.btnSignUp.next(btnHideSignUp)
    }

    getToken(TokenValue: string) {

        this.token.next(TokenValue);
    }

    setCourses(courses: string) {
        this.fetchKurs.next(courses);
    }

    setUser(user) {
        this.fetchUser.next(user);
    }

    setEmail(email) {
        this.fetchEmail.next(email);
    }

    setID(id) {
        this.fetchID.next(id);
    }

    setName(name) {
        this.fetchName.next(name);
  }

    buttonAgree(btnAgree) {
        this.i_btnAgb.next(btnAgree);
  }

    buttonAgree2(btnAgree2) {
        this.i_btnAgb2.next(btnAgree2);
  }

    setPaymentFee(paymentFee) {
        this.i_fee.next(paymentFee);
  }
  setGermailUser(gerUsr) {
    this.germailUser.next(gerUsr);
  }
  setRequiredData(i_btnRequiredData) {
    this.i_btnReqData.next(i_btnRequiredData);
  }
  setconfirmPayment(i_btnConfirmPayment) {
    this.i_btnconfPay.next(i_btnConfirmPayment);
  }
  setHidePayment(b_btnHidePayment: boolean) {
    this.b_btnhdPay.next(b_btnHidePayment)
  }
}
