<!DOCTYPE html>
<html lang="en">

  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <!-- SEO -->
    <meta name="keywords" content="HTML, CSS" />
    <meta name="description" content="login" />
    <title>login</title>
  </head>

  <body fxLayout="column" fxLayoutAlign="center center" class="d-flex">
    <div class="login-wrapper">
      <mat-card class="box">
        <mat-card-header>
          <mat-card-title class="mTitel">
            {{ 'GermailBoxLogin' | translate }}
          </mat-card-title>
        </mat-card-header>
        <br />
        <form class="form" [formGroup]="frm" (change)="formProperty(frm)" (ngSubmit)="onSubmit()">
          <mat-card-content>
            <!-- USERNAME -->
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>{{ 'GermailBoxLoginUser' | translate }}</mat-label>
              <input [type]="hide ? 'user' : 'text'" matInput id="user" formControlName="user" required #user />
              <mat-error
                *ngIf="userinpreq?.errors?.['required']                                                                                       && (userinpreq.dirty || userinpreq.touched)">
                {{ 'GermailBoxUserVal' | translate }}
              </mat-error>
              <mat-error
                *ngIf="userinpreq?.errors?.['pattern']                                                                                       && (userinpreq.dirty || userinpreq.touched)">
                {{ 'GermailBoxUserValPattern' | translate }}
              </mat-error>
            </mat-form-field>

            <!-- PASSWORD -->
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>{{ 'GermailBoxLoginPw' | translate }}</mat-label>
              <input matInput id="password" [type]="hide ? 'password' : 'text'" formControlName="password" required #password />
              <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                [attr.aria-label]="hide ? 'Show password' : 'Hide password'">
                <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
              </button>
              <mat-error *ngIf="passwordinpreq?.touched && passwordinpreq?.invalid">
                {{ 'GermailBoxUserValPw' | translate }}
              </mat-error>
            </mat-form-field>
            <a routerLink="../passwordreset">
              {{ 'ForgottenPw' | translate }}
            </a>
          </mat-card-content>
          <br /><br />

          <!-- LOGIN BUTTON -->
          <button class="loginBtn" mat-stroked-button style="color:white; padding:30px;width: 100%;"
            (click)="LogonGer(user, password)">
            {{ 'GermailBoxLoginBtn' | translate }}
          </button>
          <div></div>
        </form>
      </mat-card>
    </div>
  </body>
</html>