import { AbstractControl, ValidationErrors } from '@angular/forms';
import { promise } from 'protractor';
import { resolve } from 'url';

export class UsernameValidators {

    //####################################################################################################

    static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
        if ((control.value as string).indexOf(' ') >= 0)
            return { cannotContainSpace: true }
        return null;
    }
    static musthaveAddSign(control: AbstractControl): ValidationErrors | null {
        if ((control.value as string).indexOf('@') <= 0)
            return { musthaveAddSign: true }
        return null;
    }
    static excludeAddSign(control: AbstractControl): ValidationErrors | null {
        if ((control.value as string).indexOf('@') >= 0)
            return { excludeAddSign: true }
        return null;
    }
    //function to validate an input field for username
    static shouldBeUnique(control: AbstractControl): Promise<ValidationErrors | null> {

        return new Promise((resolve, reject) => {

            setTimeout(() => {
                console.log("ok")
                if (control.value === "Olaf")
                    resolve({ shouldBeUnique: true });
                else
                    resolve(null);

            }, 2000);
        });
    }
}
