import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  invokeForeignComponentFunction = new EventEmitter();
  subsVar: Subscription;

  invokeTopUpComponentFunction = new EventEmitter();
  subTop: Subscription;

  invokeUserComponentFunction = new EventEmitter();
  subUser: Subscription;

  invokeUserBackbtnComponentFunction = new EventEmitter();
  backUser: Subscription;

  invokeSubmitUser = new EventEmitter();
  submitUsr: Subscription;

  invokeChargeAccount = new EventEmitter();
  charAcc: Subscription;

  constructor() { }

  onForeignComponentButtonClick() {
    this.invokeForeignComponentFunction.emit();
  }
  onForeignComponentTopUpFeeClick() {
    this.invokeTopUpComponentFunction.emit();
  }
  onForeignUserClick() {
    this.invokeUserComponentFunction.emit();
  }

  onForeignUserBackClick() {
    this.invokeUserBackbtnComponentFunction.emit();
  }

  onUserSubmitClick() {
    this.invokeSubmitUser.emit();
  }

  onChargeUserAccount() {
    this.invokeChargeAccount.emit();
  }
}
