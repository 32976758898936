import { Component, NgModule, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication';
import { DataService } from '../services/data.service';
import { EventEmitterService } from '../services/event-emitter.service';
import { Gboxlangservice } from '../services/GerBoxLangService';
import { TranslateModule } from '@ngx-translate/core';
import { PaypalComponent } from '../paypal/paypal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

interface Zahlen {
  value: any;
}

@Component({
  selector: 'app-topup',
  templateUrl: './topup.component.html',
  styleUrls: ['./topup.component.css'],
  standalone: true,
  imports: [MatFormFieldModule, TranslateModule, MatIconModule, PaypalComponent],
})
export class TopupComponent implements OnInit{
  welcomeName: any;
  formEmail: any;
  swapres: any;
  mongoID: any;
  paymentFee: any;
  currentFee:any;
  public mySelection: NgModule;

  numbers: Zahlen[] = [
    { value: '1' },
    { value: '3' },
    { value: '6' },
    { value: '12' },
    { value: '15' },
    { value: '18' },
    { value: '24' },
  ];

  constructor(private data: DataService,
    private service: AuthenticationService,
    private eventEmitterService: EventEmitterService,
    public translate: Gboxlangservice) {
    this.data.email.subscribe(formEmail => this.formEmail = formEmail);
    this.data.name.subscribe(welcomeName => this.welcomeName = welcomeName);
    this.data.paymentFee.subscribe(paymentFee => this.paymentFee = paymentFee);
    this.data.setPaymentText(false);
  }
  ngOnInit() {
    this.data.setSubmitActivation(false);
    if (this.eventEmitterService.charAcc == undefined) {
      this.eventEmitterService.charAcc = this.eventEmitterService.
      invokeChargeAccount.subscribe(() => {
          this.fetchMongoID();
        });
    }
  }

  getCurrentBalanceMongoID() {
    this.service.postMongoEmail(this.formEmail)
      .subscribe(res => {
        this.currentFee = res['fee'];
        this.translate.langi18n("TopUpgetBalance","msginfofee",this.formEmail,this.currentFee)
      }, error => {
        this.translate.langi18n("TopUpGetIdErr","msgerror");
      });
  }

  fetchMongoID() {
    if (this.paymentFee == undefined) {
      this.translate.langi18n("TopUpError","msgerror");
      return;
    }
    this.service.postMongoEmail(this.formEmail)
      .subscribe(res => {
        this.swapres = res['_id'];
        this.currentFee = res['fee'];
        this.putMongoID(this.swapres);
      }, error => {
        this.translate.langi18n("TopUpGetIdErr","msgerror");
      });
  }

  putMongoID(ID) {
    let feeSum = parseInt(this.currentFee) + parseInt(this.paymentFee);
    if (this.currentFee === null) {
      feeSum = this.paymentFee;
    }
    this.service.postMongoID(ID, feeSum)
      .subscribe(res => {
        this.translate.langi18n("TopUpPutBalance","msginfofee",this.formEmail,this.paymentFee)
      }, error => {
        this.translate.langi18n("TopUpGetIdErr","msgerror");
      });
   }
}