import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class Gboxlangservice {
  fetchLanguage: string = '';
  langMessage: string = '';

  constructor(
    public toasts: ToastrService,
    public translate: TranslateService
  ) { }

  // Utility function to safely access nested properties
  private getNestedProperty(obj: any, path: string): any {
    return path.split('.').reduce((acc, key) => acc && acc[key], obj);
  }

  langi18n(lnAttr: string, resMsg: string, email?: string, fee?: string, user?: string): void {
    this.fetchLanguage = this.translate.store.currentLang;
    const translations = this.translate.store.translations;

    // Access the nested translation property
    const lang = this.getNestedProperty(translations, `${this.fetchLanguage}.${lnAttr}`);

    if (!lang) {
      console.warn('Translation not found for:', lnAttr);
      return;
    }

    switch (resMsg) {
      case 'msgerror':
        this.toasts.error(lang);
        break;
      case 'msgsuccess':
        this.toasts.success(lang);
        break;
      case 'msgmbsuccess':
        const msgMb = `"${user}@germailbox.de" ${lang}`;
        this.toasts.info(msgMb);
        break;
      case 'msginfo':
        this.toasts.info(lang);
        break;
      case 'msginfofee':
        const msgFee = `${lang}${email}, ${fee} €`;
        this.toasts.info(msgFee);
        break;
      case 'msginfoUser':
        const msgUser = `"${user}" ${lang}`;
        this.toasts.info(msgUser);
        break;
      default:
        console.warn('Unhandled message type:', resMsg);
    }
  }
}
