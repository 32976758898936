import { WelcomeComponent } from './welcome/welcome.component';
import { ContactComponent } from './contact/contact.component';
import { LogonGermailBox } from './logonGermailBox/logonGermailBox.component';
import { RegistrationComponent } from './registration/registration.component';
import { PaymentComponent } from './payment/payment.component';
import { TopupComponent } from './topup/topup.component';
import { AgbComponent } from './agb/agb.component';
import { ForgotpwComponent } from './forgotpw/forgotpw.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { Routes } from '@angular/router';


export const routesConf: Routes = [
    { path: '', redirectTo: '/welcome', pathMatch: 'full' },
    { path: 'welcome', component: WelcomeComponent },
    { path: 'login', component: LogonGermailBox },
    { path: 'signup', component: RegistrationComponent },
    { path: 'topup', component: TopupComponent },
    { path: 'payment', component: PaymentComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'passwordreset', component: ForgotpwComponent },
    { path: 'agb', component: AgbComponent },
    { path: 'impressum', component: ImpressumComponent },
];