<div class="container">
  <!-- Spinner -->
  <ngx-spinner
    class="spinner"
    color="goldenrod"
    size="medium"
    *ngIf="showSpinner"
    type="square-jelly-box"
    bdColor="rgba(51,51,51,0.5)">
    <p class="spinner" *ngIf="spinnerText">{{ 'RegistrationLoading' | translate }}</p>
    </ngx-spinner>

  <!-- Card -->
  <mat-card class="box">
    <mat-card-header>
      <mat-card-title>{{ 'ForgotPWTitel' | translate }}</mat-card-title>
      <mat-card-subtitle>
        {{ 'ForgotPWInfoText' | translate }}
      </mat-card-subtitle>
      </mat-card-header>

    <mat-card-content>
      <form [formGroup]="frm" class="form">
        <!-- Email Input -->
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>{{ 'ForgotPWGbMail' | translate }}</mat-label>
          <mat-icon matSuffix>mail</mat-icon>
          <input matInput placeholder="{{ 'ForgotPWGbMail' | translate }}" id="email" formControlName="email"
            [(ngModel)]="bmail" />
          <mat-error *ngIf="emailinpreq?.errors?.['required']">
            {{ 'ContactMailReq' | translate }}
            </mat-error>
            <mat-error *ngIf="emailinpreq?.errors?.['pattern']">
            {{ 'ContactMailValid' | translate }}
            </mat-error>
            </mat-form-field>
<!-- Submit Button -->
<div class="button-container">
  <button class="resetBtn" mat-stroked-button (click)="getBackupData()">
    {{ 'ForgotPWbtn' | translate }}
    </button>
    </div>
      </form>
    </mat-card-content>
    </mat-card>
</div>