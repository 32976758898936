import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from '../services/data.service';
import { Gboxlangservice } from '../services/GerBoxLangService';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AgbDialog } from '../agbdialog/agbdialog.component';

import { MatDialogModule } from '@angular/material/dialog';

@Component({
    selector: 'app-agb',
    templateUrl: './agb.component.html',
    styleUrls: ['./agb.component.css'],
  standalone: true,
  imports: [MatDialogModule, FormsModule, TranslateModule, MatCardModule, MatFormFieldModule, MatIconModule, MatSelectModule, ReactiveFormsModule, MatCheckboxModule]
})
export class AgbComponent  {
  checked:boolean = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;
  ch: boolean;

  constructor(public dialog: MatDialog, 
    public dataservice: DataService, 
    public translate: Gboxlangservice) {
  }

  openAGB() {
    const dialogRef = this.dialog.open(AgbDialog);
    dialogRef.afterClosed().subscribe(result => {
      this.dataservice.btnAgree2.subscribe(checked => this.checked = checked);
      console.log(`Dialog result: ${result}`);
    });
  }

  clickAGB(klick) {
    this.dataservice.buttonAgree2(klick);
  }
}

