import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ContactMail {

  private URL_IMAIL = environment.URL_IMAIL;
  private INFO_PW = environment.INFO_PW;
  private INFOMAIL = environment.INFOMAIL;
  private INQUIRYMAIL = environment.INQUIRYMAIL;
  private SUBDOMAIN = environment.SUBDOMAIN;

    constructor(private http: HttpClient) { }

    postContactMail(inpmailAdress: HTMLInputElement, inpSubject: HTMLInputElement, inpBody: HTMLInputElement) {
        let contactEmailData = {
            mailcontent: `
from email.mime.multipart import MIMEMultipart
from email.mime.text import MIMEText
import smtplib
txt ="""`+ inpBody + `<br><br>
<strong>Requester: `+ inpmailAdress + `</strong><br>
"""
message = MIMEMultipart()
message["from"] = "`+ this.INFOMAIL + `"
message["to"] = "`+ this.INQUIRYMAIL + `"
message["subject"] = "`+ inpSubject + `"
message.attach(
    MIMEText(txt, "html"))
with smtplib.SMTP(host="`+ this.SUBDOMAIN +`", port=587) as smtp:
    smtp.ehlo()
    smtp.starttls()
    smtp.login("`+ this.INFOMAIL + `", "`+ this.INFO_PW + `")
    smtp.send_message(message)
    print("Sending off ContactEmail from Customer to GermailBox...")
   `
        }
        return this.http.post(this.URL_IMAIL + '/sendInfoMail', contactEmailData);
    }
    trgRegMail() {
        return this.http.post(this.URL_IMAIL + '/triggerRegMail', "");
    }
}
