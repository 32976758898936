<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Registration</title>
</head>
<body>
  <!-- Spinner -->
  <ngx-spinner
    class="spinner"
    color="goldenrod"
    size="medium"
    *ngIf="showSpinner"
    type="square-jelly-box"
    bdColor="rgba(51,51,51,0.5)">
    <p class="spinner" *ngIf="spinnerText">{{ 'RegistrationLoading' | translate }}</p>
  </ngx-spinner>
  
  <!-- Stepper -->
  <!-- <mat-horizontal-stepper #stepper (selectionChange)="onStepChange($event)"> -->
    <mat-horizontal-stepper #stepper>
    <!-- Step 1: Information -->
    <mat-step [stepControl]="infoFormGroup">
      <form [formGroup]="infoFormGroup">
        <ng-template matStepLabel>{{ 'Stepper1' | translate }}</ng-template>
        <div fxLayout="row" fxLayoutAlign="center center" class="mat-step">
          <mat-card class="box">
            <mat-card-header>
              <mat-card-title>{{ 'Information' | translate }}</mat-card-title>
            </mat-card-header>
            <mat-card-content class="info">
              <ul>
                <li>{{ 'InfoRow1' | translate }}</li>
                <li>{{ 'InfoRow2' | translate }}</li>
                <li>{{ 'InfoRow3' | translate }}</li>
                <li>{{ 'InfoRow4' | translate }}</li>
                <li>{{ 'InfoRow5' | translate }}</li>
                <li>{{ 'InfoRow6' | translate }}</li>
                <li>{{ 'InfoRow7' | translate }}</li>
              </ul>
            </mat-card-content>
            </mat-card>
            </div>
        <div [ngClass]="{'align-right': stepper.selectedIndex === 0}">
          <button mat-button matStepperNext>{{ 'Nextbtn' | translate }}</button>
        </div>
      </form>
    </mat-step>

    <!-- Step 2: Gerbox Component -->
    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup">
        <ng-template matStepLabel>{{ 'Stepper2' | translate }}</ng-template>
        <app-gerbox></app-gerbox>
        <div [ngClass]="{'space-between': stepper.selectedIndex === 1}">
          <button mat-button matStepperPrevious>{{ 'Backbtn' | translate }}</button>
          <button mat-button matStepperNext (click)="checkReqInput(2)">{{ 'Nextbtn' | translate }}</button>
        </div>
      </form>
    </mat-step>

    <!-- Step 3: Registration Data -->
    <mat-step [stepControl]="secondFormGroup">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>{{ 'Stepper3' | translate }}</ng-template>
        <div style="margin-top: 50px;" fxLayout="column" fxLayoutAlign="space-around center">
          <mat-card class="box">
            <mat-card-header>
              <mat-card-title>{{ 'RegDataTitel' | translate }}</mat-card-title>
            </mat-card-header>
            <mat-card-content class="info">
              <p>{{ 'RegChoiceHint' | translate }}</p>
              <a>{{ 'RegEmailChoice' | translate }}</a>
              <span style="color:greenyellow; font-style: italic; font-weight: bold;">
                {{userEmail}}
              </span>
            </mat-card-content>
          </mat-card>
          <app-agb></app-agb>
        </div>
        <div [ngClass]="{'space-between': stepper.selectedIndex === 2}">
          <button mat-button matStepperPrevious (click)="backValidateUser()">{{ 'Backbtn' | translate }}</button>
          <button mat-button matStepperNext (click)="checkReqInput(3)">{{ 'Nextbtn' | translate }}</button>
        </div>
      </form>
    </mat-step>

    <!-- Step 4: Payment -->
    <mat-step [stepControl]="thirdFormGroup">
      <form [formGroup]="thirdFormGroup">
        <ng-template matStepLabel>{{ 'Stepper4' | translate }}</ng-template>
        <div class="container">
          <app-paypal></app-paypal>
        </div>
        <div [ngClass]="{'align-left': stepper.selectedIndex === 3}">
          <button mat-button matStepperPrevious>{{ 'Backbtn' | translate }}</button>
        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</body>
</html>
