import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { UsernameValidators } from '../services/username.validators';
import { AuthenticationService } from '../services/authentication';
import { Router, ActivatedRoute } from "@angular/router";
import { DataService } from '../services/data.service';
import { Gboxlangservice } from '../services/GerBoxLangService';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';

let passwort, username: any;
let usrEmail: any = username;
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
  standalone: true,
  imports: [CommonModule, MatInputModule, MatButtonModule, FormsModule, TranslateModule, MatCardModule, MatFormFieldModule, MatIconModule, MatSelectModule, NgxSpinnerModule, ReactiveFormsModule]
})


export class PaymentComponent {
  btnShow: boolean = false;
  btnShow2: boolean = true;
  tkn: any;
  showSpinner = true;
  spinnerText = false;
  fetchLanguage: string;
  langMessage: string;

  frm = new UntypedFormGroup({
    email: new UntypedFormControl('', [
      Validators.required,
      //Validators.email,
      UsernameValidators.cannotContainSpace,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]
    ),

    password: new UntypedFormControl('', [
      Validators.required,
    ]),

    filesubmit: new UntypedFormControl(),

  });

  formProperty(f) {
    let uname: string = this.frm.value.password;
    console.log(f);

  }

  get emailinpreq() {
    return this.frm.get('email');
  }
  hide = true;
  get passwordinpreq() {
    return this.frm.get('password');
  }

  //################## End Form Validation ################################################################################

  constructor(
    public translate: Gboxlangservice,
    private service: AuthenticationService,
    private router: Router,
    private bService: DataService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
  ) {
  }

  spin() {
    this.spinner.show();
    this.spinnerText = true;
  }

  TopUpLogin(user, pw) {

    if (!this.frm.valid) {
      this.translate.langi18n("PaymentLogonErr", "msgerror");
      return;
    }
    let benutzer = user.value;
    let passwort = pw.value;
    this.service.postUsrCr(benutzer, passwort)
      .subscribe(res => {
        let sToken: any;
        sToken = res;
        let bName = benutzer.match(/[^@]*/);
        let ucName = "";
        for (let i = 0; i < bName.length; i++) {
          let firstLetter = bName[i];
          ucName = firstLetter.charAt(0).toUpperCase() + firstLetter.slice(1);
        }
        this.bService.setName(ucName);
        this.bService.changeValue(this.btnShow);
        this.bService.changeValue2(this.btnShow2);
        this.bService.setEmail(benutzer);
        this.bService.getToken(sToken);
        let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl')
        this.router.navigate([returnUrl || '/topup']);
      }, error => {
        passwort = pw.value;
        this.fetchMongoID(benutzer, passwort);
        this.translate.langi18n("GermailBoxLogonError", "msgerror");
      });

  }

  changeMongoUserPW(ID, passwort) {
    this.service.putMongoUserPW(ID, passwort)
      .subscribe(res => {
      }, error => {
        //this.translate.langi18n("PaymentMongoChPwErr","msgerror");
      });
  }

  fetchMongoID(usrEmail, passwort) {
    this.service.postMongoEmail(usrEmail)
      .subscribe(res => {
        let message = JSON.stringify(res);
        let _id = res['_id'];
        let uPW = passwort;
        this.changeMongoUserPW(_id, uPW);
      }, error => {
        //this.translate.langi18n("PaymentMongoChPwErr","msgerror");
      });
  }

  onSubmit() {
    //this.frm.reset();
  }
}
