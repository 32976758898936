import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from '../services/data.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private API_URL= environment.API_URL;

  private token: string = "";
  private tkn = this.data.TokenValue.subscribe(_value => this.token = _value);

  constructor(private http: HttpClient, private data: DataService) {
  }

  postUsrCr(inpMail: HTMLInputElement, inpPW: HTMLInputElement) {
    let credentials = {
      email: inpMail,
      password: inpPW
    }
    return this.http.post(this.API_URL + '/auth/postAuth', credentials);
  }

  postMongoEmail(inpMail: HTMLInputElement) {
    let mongoMail = {
      email: inpMail
    }
    return this.http.post(this.API_URL + '/users/me/email', mongoMail, { headers: { 'x-auth-token': this.token } });
  }

  postMongoID(inpID, feeVal) {
    let euro = {
      fee: feeVal
    }
    return this.http.put(this.API_URL + '/users/me/topup/' + inpID, euro, { headers: { 'x-auth-token': this.token } });
  }

  putMongoUserPW(inpID, pw) {
    let cred = {
      password: pw
    }
    return this.http.put(this.API_URL + '/users/me/chpw/' + inpID, cred, { headers: { 'x-auth-token': this.token } });
  }


}
