import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UserRegistration } from '../services/registration.service';
import { Gboxlangservice } from '../services/GerBoxLangService';
import { GerboxRegistration } from '../services/gerboxreg';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';


@Component({
    selector: 'app-forgotpw',
    templateUrl: './forgotpw.component.html',
    styleUrls: ['./forgotpw.component.css'],
  standalone: true,
  imports: [MatInputModule, MatButtonModule, CommonModule, FormsModule, TranslateModule, MatCardModule, MatFormFieldModule, MatIconModule, MatSelectModule, ReactiveFormsModule, MatCheckboxModule, NgxSpinnerModule]
})
export class ForgotpwComponent implements OnInit {
  swapres: any;
  bmobile: string="";
  bmail: string = "";
  bmail2: string = "";
  mailuser: string = "";
  changedpw : any;
  user: string = "";
  spinnerText = false;
  showSpinner = true;
  

  //############## Form Validation ######################################################################################

  
    frm = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]
      ),
      // email2: new FormControl('', [
      //   Validators.required,
      //   Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]
      // ),
      // mobile: new FormControl('', [
      //   Validators.required,
      // ]),
    });

    get emailinpreq() {
      return this.frm.get('email');
    }
    // get emailinpreq2() {
    //   return this.frm.get('email2');
    // }

    // get mobileNum() {
    //   return this.frm.get('mobile');
    // }
  
    keyPressNumbers(event: any) {
      const pattern = /[0-9\+\-\ ]/;
      let inputChar = String.fromCharCode(event.charCode);
      if (event.keyCode != 8 && !pattern.test(inputChar)) {
        event.preventDefault();
      }
    }
    //################## End Form Validation ################################################################################
  
    constructor(private usersrv: UserRegistration,
                public translate:Gboxlangservice,
                private service: GerboxRegistration,
                private spinner: NgxSpinnerService){
    }
  
    ngOnInit() {
 
    }

    spin() {
      this.spinner.show();
      this.spinnerText = true;
    }

    getBackupData(){
    if (!this.frm.valid) {
      return this.translate.langi18n("ForgotPWEntryCheck","msgerror");
    }  
    this.usersrv.getUsr()
      .subscribe(response => {
        let swapres: any = response;
        for (let i = 0;i < swapres.length; i++) {
          if (this.bmail === swapres[i].email){
            this.mailuser = swapres[i].backupemail;
            this.user = swapres[i].name;
          }
        }
        if(this.mailuser === ""){
          return this.translate.langi18n("ForgotPWEntryCheck2","msgerror");
        }
        this.resetPassword();
      }, error => {
        this.translate.langi18n("ForgotPWgetDataErr","msgerror");
      });
    }

    resetPassword(){
      let randomstring = Math.random().toString(36).slice(-12);
      this.changedpw = randomstring;
      this.service.changePassword(this.bmail, this.changedpw)
        .subscribe(response => {
          this.spin();
          this.sendPwMail();
          console.log(response);
        }, error => {
          this.translate.langi18n("ForgotPWresetErr","msgerror");
        });
      }

    sendPwMail(){
       this.service.postInfoPwChange(this.bmail,this.mailuser,this.changedpw,this.user)
         .subscribe(response => {
           this.triggerPwChgMail();
           console.log(response);
         }, error => {
           this.translate.langi18n("ForgotPWSendMailErr","msgerror");
         });
       }

    triggerPwChgMail() {
      this.service.trgPwMail()
       .subscribe(response => {
        this.spinner.hide();
        this.translate.langi18n("ForgotPWMailSuccess","msgsuccess");
          console.log(response);
        }, error => {
          this.translate.langi18n("ForgotPWTrgMailErr","msgerror");
          });
      }
  }
