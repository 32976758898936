import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GerboxRegistration {

    private URL_GET_MB = environment.URL_GET_MB;
    private URL_CREATE_MB = environment.URL_CREATE_MB;
    private URL_IMAIL = environment.URL_IMAIL;
    private API_KEY = environment.API_KEY;
    private INFO_PW = environment.INFO_PW;
    private URL_EDIT = environment.URL_EDIT;
    private DOMAIN = environment.DOMAIN;
    private ADDMAIL = environment.ADDMAIL;
    private INFOMAIL = environment.INFOMAIL;
    private SUBDOMAIN = environment.SUBDOMAIN;

    private headers = new HttpHeaders({'Content-Type': 'application/json', 'X-API-Key': this.API_KEY});
    constructor(private http: HttpClient) { }

    getMailbox() {
        console.log("getMailbox....");
        return this.http.get(this.URL_GET_MB + '/all', { headers: this.headers });
    }

    postMailBox(inpUser: HTMLInputElement, inpPW: HTMLInputElement) {
        let regData = {
            active: "1",
            domain: this.DOMAIN,
            local_part: inpUser,
            name: inpUser,
            password: inpPW,
            password2: inpPW,
            quota: "1024"
        }
        return this.http.post(this.URL_CREATE_MB, regData, { headers: this.headers });
    }

    changePassword(inpEmail,inpPw) {
        let chgData = {
            "items": [
              inpEmail
            ],
            "attr": {
              "name": "ramon",
              "quota": "1024",
              "password": inpPw,
              "password2": inpPw,
              "active": "1",
              "force_pw_update": "0",
              "sogo_access": "1"
            }
          }
        return this.http.post(this.URL_EDIT, chgData, { headers: this.headers });
    }


    postInfoMail(inpUser: HTMLInputElement, inpPW: HTMLInputElement) {
        let infoEmailData = {
            mailcontent: `
from email.mime.multipart import MIMEMultipart
from email.mime.text import MIMEText
import smtplib
txt ="""<p>Hello `+ inpUser + `,</p>
welcome at GermailBox, we are pleased to be your new email provider.<p></p>

<strong>
User: `+ inpUser +``+ this.ADDMAIL + `</strong><p></p>
<strong>
Password: `+ inpPW + `
</strong><p></p>
<br><br>
Enjoy your new Email account with full privacy and non shared information.<p></p>

Regards<p></p>

Your GermailBox Team :-)<p></p>
"""
message = MIMEMultipart()
message["from"] = "`+ this.INFOMAIL + `"
message["to"] = "`+ inpUser + ``+ this.ADDMAIL + `"
message["subject"] = "Thank you for registering at GermailBox.de :-) "
message.attach(
    MIMEText(txt, "html"))
with smtplib.SMTP(host="`+ this.SUBDOMAIN +`", port=587) as smtp:
    smtp.ehlo()
    smtp.starttls()
    smtp.login("`+ this.INFOMAIL + `", "`+ this.INFO_PW + `")
    smtp.send_message(message)
    print("Sending off the InfoRegEmail for GermailBox...")
   `
        }
        return this.http.post(this.URL_IMAIL + '/sendInfoMail', infoEmailData);
    }

    trgRegMail() {
        return this.http.post(this.URL_IMAIL + '/triggerRegMail', "");
    }

    postInfoPwChange(inpMail, inpBackUpMail,inpPW,inpUser) {
        let pwEmailData = {
            mailcontent: `
from email.mime.multipart import MIMEMultipart
from email.mime.text import MIMEText
import smtplib
txt ="""<p>Hello `+ inpUser + `,</p>
your password has been reset successfully :-).<p></p>
<br>
<strong>
User: `+ inpMail + `</strong><p></p>
<strong>
Password: `+ inpPW + `
</strong><p></p>
<br>
<strong>
Note: This should be a temporary password, so please change this as soon as you have entered you email account again.
You can change your password in the "settings -> password area"!
</strong>
<br><br>
In case you did not trigger this process, please contact GermailBox.<p></p>
<br>
Regards<p></p>
<br>
Your GermailBox Team :-)<p></p>
"""
message = MIMEMultipart()
message["from"] = "`+ this.INFOMAIL + `"
message["to"] = "`+ inpBackUpMail + `"
message["subject"] = "Your password was successfully reset at GermailBox.de :-) "
message.attach(
    MIMEText(txt, "html"))
with smtplib.SMTP(host="`+ this.SUBDOMAIN +`", port=587) as smtp:
    smtp.ehlo()
    smtp.starttls()
    smtp.login("`+ this.INFOMAIL + `", "`+ this.INFO_PW + `")
    smtp.send_message(message)
    print("Sending off the InfoPwChange for GermailBox...")
   `
        }
        return this.http.post(this.URL_IMAIL + '/sendPwChangeMail', pwEmailData);
    }
    trgPwMail() {
        return this.http.post(this.URL_IMAIL + '/triggerPwMail', "");
    }
}
