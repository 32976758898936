import { Component, OnInit } from '@angular/core';
import { Gboxlangservice } from '../services/GerBoxLangService';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-impressum',
    templateUrl: './impressum.component.html',
    styleUrls: ['./impressum.component.css'],
  standalone: true,
  imports: [CommonModule, MatCardModule, TranslateModule, RouterModule]
})
export class ImpressumComponent implements OnInit {

  constructor(public translate: Gboxlangservice) { }

  ngOnInit(): void {
  }

}
