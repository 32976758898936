<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <!-- Adapting the size for different browsers and mobile devices-->
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <!--SEO optimization -->
    <meta name="keywords" content="HTML, CSS" />
    <meta name="description" content="topup" />
    <title>topup</title>
  </head>
  <body>
    <div class="flex-container">
      <div class="headerContainer">
        <h1>{{'WelcomeTopUp'|translate}} <strong>{{ welcomeName
            }}</strong>,{{"TopUpText"|translate}}<mat-icon>mail_outline</mat-icon></h1>
      </div>
      <mat-label >{{'TopUpText2'|translate}}<strong>{{formEmail}}</strong>{{"TopUpText3"|translate}}</mat-label>
      <div><app-paypal></app-paypal></div>
    <div class="btnContainer">
      <button class="creditBtn" mat-stroked-button (click)="getCurrentBalanceMongoID()">
        {{'TopUpbtnBalance'|translate}}
      </button>
      </div>
  </div>
  </body>
</html>
