import { Component, OnInit } from '@angular/core';
import { GerboxLogon } from '../services/logon.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Gboxlangservice } from '../services/GerBoxLangService';
import { environment } from '../../environments/environment';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-logonGermailBox',
  templateUrl: './logonGermailBox.component.html',
  styleUrls: ['./logonGermailBox.component.css'],
  standalone: true,
  imports: [RouterModule, FormsModule, ReactiveFormsModule, TranslateModule, CommonModule, MatCardModule, MatFormFieldModule, MatIconModule, MatSelectModule, MatCheckboxModule, MatTooltipModule, MatInputModule, MatButtonModule]
})
export class LogonGermailBox implements OnInit {

  private URL_LOGIN = environment.URL_LOGIN;
  fetchLanguage: string;
  langAttr: string;
  langMessage: string;
  resMsg: string;
  showPassword = false;

  frm = new UntypedFormGroup({
    user: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),
    password: new UntypedFormControl('', [
      Validators.required,
    ]),
    filesubmit: new UntypedFormControl(),
  });

  hide = true;

  constructor(
    private service: GerboxLogon,
    public translate: Gboxlangservice
  ) { }

  ngOnInit(): void { }

  get userinpreq() {
    return this.frm.get('user');
  }
  get passwordinpreq() {
    return this.frm.get('password');
  }

  formProperty(f) {
    console.log('form changes => ', f.value);
  }

  LogonGer(user, pw) {
    if (user.value === '' || pw.value === '') {
      this.translate.langi18n('TopUpError', 'msgerror');
      return;
    }

    const benutzer = user.value;
    const passwort = pw.value;
    this.service.postUserCreds(benutzer, passwort).subscribe({
      next: (response) => {
        window.location.href = this.URL_LOGIN;
      },
      error: (error) => {
        this.translate.langi18n('GermailBoxLogonError', 'msgerror');
      },
    });
  }

  forgotPassword() {
    this.translate.langi18n('PwRequestInfo', 'msginfo');
  }
  togglePasswordVisibility(event: MouseEvent, field: string): void {
    event.preventDefault();
    event.stopPropagation();
    this.showPassword = !this.showPassword;
  }
  onSubmit() {
    //this.frm.reset();
  }
}
