<div class="custom-container">
  <mat-card class="impressum-card">
    <mat-card-header>
      <mat-card-title>Impressum</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <h3>Company Information</h3>
      <p><strong>Company Name:</strong> frenzelTech.</p>
      <p><strong>Address:</strong> Hohlstrasse 413, Zürich, Switzerland</p>
      <p><strong>Phone:</strong> +417978765488</p>
      <p><strong>Email:</strong> info&#64;costcontrol.ch</p>

      <h3>Legal Information</h3>
      <p><strong>VAT ID:</strong> CH798845</p>
      <p><strong>Commercial Register:</strong> HRB 76548</p>
      <p><strong>Register Court:</strong> </p>

      <h3>Responsible for Content</h3>
      <p>Richard Vogler</p>
      <p><strong>Address:</strong> Fressgasse 15, 69076 Frankfurt am Main, Deutschland</p>
      <p><strong>Email:</strong> r.vogler&#64;ffm.de</p>
    </mat-card-content>
  </mat-card>
</div>