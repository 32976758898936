<!DOCTYPE html>
<html lang="en">

  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="keywords" content="HTML, CSS" />
    <meta name="description" content="contact" />
    <title>contact</title>
  </head>

  <body class="d-flex flex-column">
    <form class="email-form" [formGroup]="myForm" (change)="formProperty(myForm)" (ngSubmit)="onSubmit()">
      <mat-card class="box">
        <mat-card-header>
          <mat-card-title class="mTitel">
            {{'ContactTitel'|translate}}
          </mat-card-title>
        </mat-card-header>
        <br />
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>{{'ContactMailadress'|translate}}</mat-label>
          <input matInput id="subject" formControlName="email" placeholder="Please enter your email adress" #mailadress
            required />
          <mat-error *ngIf="emailinpreq?.errors?.['required']">
            {{'ContactMailReq'|translate}}
          </mat-error>
          <mat-error *ngIf="emailinpreq?.errors?.['pattern']">
            {{ 'GermailBoxUserValPattern' | translate }}
          </mat-error>
          </mat-form-field>
            <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{ 'ContactSubjTitel' | translate }}</mat-label>
            <input matInput id="subject" formControlName="subject" placeholder="Enter subject" required #subj />
            <mat-error *ngIf="myForm.get('subject')?.touched && myForm.get('subject')?.hasError('required')">
              {{ 'ContactMailSubject' | translate }}
            </mat-error>
            </mat-form-field>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{'ContactBody'|translate}}</mat-label>
          <textarea matInput id="body" formControlName="body" placeholder="Write your message...." #body required></textarea>
          <mat-error *ngIf="myForm.get('body')?.touched && myForm.get('body')?.hasError('required')">
            {{'ContactBodyReq'|translate}}
          </mat-error>
          </mat-form-field>
        <button (click)="sendMail(mailadress.value, subj.value, body.value)" class="contactBtn" mat-stroked-button
          style="color:white; padding:30px;width: 100%;">
          {{'ContactMailbtn'|translate}}
        </button>
      </mat-card>
    </form>
  </body>
</html>