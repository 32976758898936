<!DOCTYPE html>
<html lang="en">

  <head>
    <meta charset="UTF-8" />
    <!-- Adapting the size for different browsers and mobile devices-->
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <!--SEO optimization -->
    <meta name="keywords" content="HTML, CSS" />
    <meta name="description" content="sign up" />
    <title>sign up</title>
  </head>
  <!-- <body class="d-flex flex-column"> -->
  <ngx-spinner class="spinner" color="goldenrod" size="medium" *ngIf="showSpinner" type="square-jelly-box"
    bdColor="rgba(51,51,51,0.5)">
    <p *ngIf="spinnerText">{{'RegistrationLoading'|translate}}</p>
    </ngx-spinner>
    <div class="container">
      <mat-card class="box">
        <mat-card-header>
          <mat-card-title class="mTitel">{{'GerboxTitel1'|translate}}</mat-card-title>
        </mat-card-header>
        <br><br>
      <form class="form" [formGroup]="frm" (ngSubmit)="onSubmit()">
        <mat-card-content>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'GermailBoxLoginUser' | translate }}</mat-label>
            <mat-icon matSuffix matTooltip="{{ 'GerboxTooltip' | translate }}">perm_identity</mat-icon>
            <input matInput placeholder="{{'GerboxUsername'|translate}}" id="user" formControlName="user" [(ngModel)]="germailuser"
              (change)="getGermailUser(germailuser)" required />
            <!-- Required error -->
            <mat-error *ngIf="frm.get('user').hasError('required') && (frm.get('user').dirty || frm.get('user').touched)">
              {{ 'GermailBoxUserVal' | translate }}
            </mat-error>
          
            <!-- Minlength error -->
            <mat-error *ngIf="frm.get('user').hasError('minlength') && (frm.get('user').dirty || frm.get('user').touched)">
              {{ 'GerboxUserMin' | translate }}
              {{ frm.get('user').errors.minlength.requiredLength }}
              {{ 'GerboxUserChar' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'GermailBoxLoginPw' | translate }}</mat-label>
            <input id="password" matInput placeholder="{{'GerboxPW'|translate}}" [type]="hide ? 'password' : 'text'"
              formControlName="password" [(ngModel)]="gpassword" required />
            <mat-icon matSuffix (click)="hide = !hide">{{
              hide ? "visibility_off" : "visibility"
              }}</mat-icon>
            <mat-hint *ngIf="!passwordinpreq.value"></mat-hint>
            <mat-error *ngIf="frm.get('password')?.hasError('required')">
              {{ 'GerboxPWReq' | translate }}
            </mat-error>
            <mat-error *ngIf="frm.get('password')?.hasError('minlength')">
              {{ 'GerboxPwMin' | translate }}
              {{ frm.get('password')?.errors?.minlength?.requiredLength }}
              {{ 'GerboxPwMinChar' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'GermailBoxLoginPw' | translate }}</mat-label>
            <input id="password2" matInput placeholder=" {{'GerboxPwConfirm'|translate}}" [type]="hide2 ? 'password' : 'text'"
              formControlName="password2" [(ngModel)]="gpassword2" required />
            <mat-icon matSuffix (click)="hide2 = !hide2">{{
              hide2 ? "visibility_off" : "visibility"
              }}</mat-icon>
            <mat-hint *ngIf="!password2inpreq.value"></mat-hint>
            <mat-error *ngIf="frm.get('password2')?.hasError('required')">
              {{ 'GerboxPWReq' | translate }}
            </mat-error>
            <mat-error *ngIf="frm.get('password2')?.hasError('minlength')">
              {{ 'GerboxPwMin' | translate }}
              {{ frm.get('password2')?.errors?.minlength?.requiredLength }}
              {{ 'GerboxPwMinChar' | translate }}
            </mat-error>
          </mat-form-field>
        </mat-card-content>
        </form>
        </mat-card>


    <!--#########################  Optional Data ##########################################################################-->

    <form class="form" [formGroup]="frm" (ngSubmit)="onSubmit()">
      <mat-card class="box2">
        <mat-card-header>
          <mat-card-title class="mTitel2">{{'GerboxOptData'|translate}}</mat-card-title>
        </mat-card-header>
        <br><br>
        <mat-card-content>
          <mat-form-field appearance="outline">
            <mat-icon matSuffix>mail</mat-icon>
            <input matInput placeholder="{{'GerboxBackupMail'|translate}}" id="email" formControlName="email" [(ngModel)]="bmail" />
            <mat-error
              *ngIf="emailinpreq?.errors?.['pattern']                                                                                       && (userinpreq.dirty || userinpreq.touched)">
              {{ 'GermailBoxUserValPattern' | translate }}
            </mat-error>
            <mat-error *ngIf="emailinpreq?.errors?.['pattern']">
              {{'ContactMailValid'|translate}}
            </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-icon matSuffix>phone</mat-icon>
            <input id="mobile" formControlName="mobile" matInput placeholder="{{'GerboxMobile' | translate}}"
              (keypress)="keyPressNumbers($event)" />
            <mat-error *ngIf="frm.get('mobile')?.errors?.['minlength']">
              {{ 'GerboxMobileReqMin' | translate }}
              {{ frm.get('mobile')?.errors?.minlength?.requiredLength }}
              {{ 'GerboxMobileReqNum' | translate }}
            </mat-error>
            </mat-form-field>
        </mat-card-content>
      </mat-card>
      <div class="tooltip">Hover over me
        <span class="tooltiptext">Tooltip text</span>
      </div>
    </form>
  </div>
  <!-- </body> -->

</html>